@tailwind base;

@layer base {
    :host {
      font-family: inherit;
    }
  }

@tailwind components;
@tailwind utilities;
