.leaflet-touch .leaflet-control-container .leaflet-bar {
  border: 0;

  & a {
    height: auto;
    width: auto;
    border: 0;
    border-radius: 0.375rem !important;
    overflow: hidden;
  }

  .leaflet-control-zoom-out {
    margin-top: 0.5rem;
  }
}

.map-background {
  filter: blur(3rem);
  opacity: 0.5;
}

body .leaflet-bottom {
  bottom: 5rem;
}
@media all and (min-width: 992px) {
  body .leaflet-bottom {
    bottom: 0;
  }
}

.hotspot {
  transition: stroke-width 0.2s ease-in-out, stroke 0.2s ease-in-out,
    fill-opacity 0.1s ease-in-out;
}

.layer {
  fill-opacity: var(--hotspot-matches);
}

.hotspot.active {
  fill-opacity: var(--hotspot-active);
}

.leaflet-popup-content-wrapper {
  padding: 0 !important;
  box-shadow: none !important;
}

.leaflet-popup-content {
  margin: 0 !important;
  width: 276px !important;
}

.leaflet-container a {
  color: var(--dark);
}

.leaflet-bar a.leaflet-disabled {
  --primary: rgba(var(--primary), 0.6);
  --contrast_primary: rgba(var(--primary_contrast), 0.6);
  --contrast_secondary: rbga(var(--secondary_contrast), 0.6);
}

.leaflet-right .leaflet-control {
  margin-right: 1rem;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 2rem;
}

@media all and (min-width: 1024px) {
  .leaflet-bottom .leaflet-control {
    margin-bottom: 1rem;
  }
}
