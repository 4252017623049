
/*
@link: https://fancyapps.com/carousel/plugins/navigation/
*/
.f-carousel {
  --f-button-width: 38px;
  --f-button-height: 38px;

  --f-button-svg-width: 24px;
  --f-button-svg-height: 24px;
  --f-button-svg-stroke-width: 2.5;

  --f-button-svg-fill: currentColor;
  --f-button-color: #000;
  --f-button-border-radius: 50%;
  --f-button-shadow: none;

  --f-button-bg: transparent;
  --f-button-color: #000;
  --f-button-hover-color: var(--primary);
  --f-button-hover-bg: transparent;
  --f-carousel-dot-color: #000;
  --f-carousel-dot-opacity: 1;
  --f-carousel-dot-hover-opacity: 1;

  --f-button-transition: width 0.25s ease;
}

.f-carousel__dots [aria-selected="true"] .f-carousel__dot {
  --f-carousel-dot-color: var(--primary);
  --f-carousel-dot-width: 3rem;
  --f-carousel-dot-radius: 6px;
  transition: var(--f-button-transition);
}

.f-carousel__dots button:hover .f-carousel__dot {
  --f-carousel-dot-color: var(--primary);
}