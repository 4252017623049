.ant-select.ant-select-multiple .ant-select-selection-item {
    --ant-select-multiple-item-bg: transparent;
    
    align-items: center;
}

/* Select items */
.ant-select:not(.ant-select-customize-input) {
    --ant-color-primary: var(--primary);
    --ant-color-primary-hover: var(--primary);
    --ant-color-text-quaternary: var(--primary);
    --ant-color-text-placeholder: #000;
    --ant-select-selector-bg: #fff;
    --ant-color-text: #000;
    --ant-motion-duration-slow: 0.15s;
    --ant-padding-sm: 1rem;
    --ant-select-show-arrow-padding-inline-end: 1rem;
    --ant-font-size: 0.875rem;
    --ant-select-multiple-item-height: 1.875rem;

    border: none;

    &.ant-select-single {
        height: auto;
    }

    &.ant-select-focused,
    &.ant-select-open {
        .ant-select-selector {
            --ant-color-text: var(--contrast_primary);
            --ant-color-border: var(--primary);
            --ant-select-selector-bg: rgb(from var(--primary) r g b / 1);
            --ant-color-text-placeholder: var(--contrast_primary);
            color: var(--ant-color-text);
        }

        .ant-select-arrow {
            color: rgb(from var(--contrast_primary) r g b / 0.5);
        }
    }

    &.ant-select-multiple {
        padding: 0 calc(var(--ant-padding-sm) - 1px);
        padding-inline: 3px;
    }
    
    .ant-select-selection-item-content {
        line-height: calc(var(--ant-control-height) - var(--ant-line-width)* 2);
    }

    .ant-select-selector {
        padding-top: 3px;
        padding-bottom: 3px;
        box-shadow: none !important;
    }

    @media all and (min-width: 1024px) {
        .ant-select-selector {
        border: none !important;
        }
    }

    &.border-none .ant-select-selector {
        border: none !important;
    }
}


/* Dropdown items */
.ant-select-dropdown .rc-virtual-list .ant-select-item {
    --ant-select-option-active-bg: var(--secondary);
    --ant-select-option-selected-bg: var(--secondary);
    --ant-color-text: var(--contrast_secondary);

    &.ant-select-item-option-active {
        --ant-color-text: var(--contrast_secondary);
    }
    .ant-select-item-option-state {
        color: var(--ant-color-text);
    }
}